<template>
  <b-modal
    id="form-article"
    :visible="shallShowArticleFormModal"
    :title="`${$t('Form')} ${$t('Article')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    no-close-on-esc
    @change="(val) => $emit('update:shall-show-article-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeArticleForm)} ${$t('Article')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-article-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeArticleForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeArticleForm === 'Add' || typeArticleForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: Type -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Type')"
                      label-for="article-type"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Type')"
                        rules="required"
                      >
                        <v-select
                          id="article-type"
                          v-model="articleData.type"
                          :options="articleData.typeOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :placeholder="$t('Type')"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Type')"
                      label-for="article-type"
                    >
                      <span class="form-info-box">{{ typeToText(articleData.type) }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Suggest -->
                  <b-col
                    cols="2"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Set Suggest')"
                      label-for="article-is-suggest"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Set Suggest')"
                        rules="required"
                      >
                        <b-form-checkbox
                          v-model="articleData.is_suggest"
                          switch
                          inline
                        >
                          <span>{{ (articleData.is_suggest === true)? $t('Suggest') : $t('Normal') }}</span>
                        </b-form-checkbox>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Set Suggest')"
                      label-for="article-is-suggest"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isSuggestVariant(articleData.is_suggest)}`"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(articleData.is_suggest)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Status -->
                  <b-col
                    cols="4"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="article-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="articleData.status"
                          name="article-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="articleData.status"
                          name="article-status"
                          value="inactive"
                          class="custom-control-info cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="article-status"
                    >
                      <span class="form-info-box">
                        <b-badge
                          pill
                          :variant="`light-${isStatusVariant(articleData.status)}`"
                          class="text-capitalize"
                        >
                          {{ $t(textFirstUpper(articleData.status)) }}
                        </b-badge>
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Subject -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Subject')"
                      label-for="article-subject"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Subject')"
                        rules="required"
                      >
                        <b-form-textarea
                          id="article-subject"
                          v-model="articleData.subject"
                          rows="2"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          style="height: 60px;"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Subject')"
                      label-for="article-subject"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="articleData.subject" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Title -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Title')"
                      label-for="article-title"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Title')"
                        rules="required"
                      >
                        <b-form-textarea
                          id="article-title"
                          v-model="articleData.title"
                          rows="2"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          style="height: 60px;"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Title')"
                      label-for="article-title"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="articleData.title" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>
                  <!-- Field: Content -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Content')"
                      label-for="article-content"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Content')"
                        rules="required"
                      >
                        <vue-editor
                          id="article-content"
                          v-model="articleData.content"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                          :editor-toolbar="customToolbar"
                        />
                        <!-- use-custom-image-handler
                          @image-added="handleImageAdded"
                          @image-removed="handleImageRemoved" -->
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Content')"
                      label-for="article-content"
                    >
                      <span class="form-info-box">
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span v-html="articleData.content" />
                      </span>
                    </b-form-group>
                  </b-col>

                </b-row>
                <b-row>

                  <!-- Field: Image -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Image')"
                      label-for="article-image"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Image')"
                        :rules="(typeArticleForm === 'Add') ? 'required' : ''"
                      >
                        <div class="file-upload-form">
                          <b-form-file
                            v-model="articleData.image_file"
                            accept="image/*"
                            placeholder="เลือกไฟล์รูปภาพ..."
                            no-drop
                            @change="previewImage"
                          />
                        </div>
                        <div
                          v-if="articleData.image_url !== ''"
                          style="padding: 10px;"
                        >
                          <img
                            :src="articleData.image_url"
                            style="height: 120px;"
                          >
                        </div>
                        <div
                          v-if="articleData.image_data != ''"
                          style="padding: 10px;"
                        >
                          <img
                            :src="articleData.image_data"
                            style="height: 120px;"
                          >
                        </div>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Image')"
                      label-for="article-channel-id"
                    >
                      <span class="form-info-box">{{ articleData.image }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Author -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeArticleForm === 'Add' || typeArticleForm === 'Edit')"
                      :label="$t('Author')"
                      label-for="article-author"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Author')"
                        rules="required"
                      >
                        <v-select
                          id="article-author"
                          v-model="articleData.author_id"
                          :options="articleData.authorOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                          :placeholder="$t('Author')"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Author')"
                      label-for="article-author"
                    >
                      <span class="form-info-box">{{ articleData.author_name }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BBadge,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import {
  VueEditor,
} from 'vue2-editor'
import tableArticle from './tableArticle'

export default {
  directives: {
    Ripple,
  },
  components: {
    VueEditor,
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BBadge,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    vSelect,
  },
  model: {
    prop: 'shallShowArticleFormModal',
    event: 'update:shall-show-article-form-modal',
  },
  props: {
    shallShowArticleFormModal: {
      type: Boolean,
      required: true,
    },
    typeArticleForm: {
      type: String,
      default: 'Add',
    },
    articleData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      customToolbar: [
        [{ font: [] }],

        [{ header: [false, 1, 2, 3, 4, 5, 6] }],

        [{ size: ['small', false, 'large', 'huge'] }],

        ['bold', 'italic', 'underline', 'strike'],

        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' },
        ],

        [{ header: 1 }, { header: 2 }],

        ['blockquote', 'code-block'],

        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],

        [{ script: 'sub' }, { script: 'super' }],

        [{ indent: '-1' }, { indent: '+1' }],

        [{ color: [] }, { background: [] }],

        ['link', 'image', 'video', 'formula'],

        [{ direction: 'rtl' }],
        ['clean'],
      ],
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeArticleForm === 'Add') {
        delete this.articleData.image_data
        delete this.articleData.image_url
        store.dispatch('store-article/addArticle', this.articleData)
          .then(response => {
            if (this.articleData.image_file !== null) {
              this.saveImage(response.data.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Added'),
                text: this.$i18n.t('Your data has been added'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.$emit('article-form-update')
              this.$emit('update:shall-show-article-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeArticleForm === 'Edit') {
        delete this.articleData.image_data
        delete this.articleData.image_url
        store.dispatch('store-article/editArticle', { id: this.articleData.id, data: this.articleData })
          .then(() => {
            if (this.articleData.image_file !== null) {
              this.updateImage(this.articleData.id)
            } else {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.busy = false
              this.processing = false

              this.$emit('article-form-update')
              this.$emit('update:shall-show-article-form-modal', false)
            }
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeArticleForm === 'Delete') {
        store.dispatch('store-article/deleteArticle', {
          id: this.articleData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('article-form-update')
            this.$emit('update:shall-show-article-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    saveImage(id) {
      const formData = new FormData()
      formData.append('file', this.articleData.image_file)

      store.dispatch('store-article/uploadImageArticle', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.articleData.image_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Added'),
            text: this.$i18n.t('Your data has been added'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('article-form-update')
          this.$emit('update:shall-show-article-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    updateImage(id) {
      const formData = new FormData()
      formData.append('file', this.articleData.image_file)

      store.dispatch('store-article/uploadImageArticle', { id, data: formData })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.busy = false
          this.processing = false
          this.articleData.image_file = null

          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Updated'),
            text: this.$i18n.t('Your data has been updated'),
            timer: 3000,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.$emit('article-form-update')
          this.$emit('update:shall-show-article-form-modal', false)
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    onDiscard() {
      this.$emit('discard-article-form')
      this.$emit('update:shall-show-article-form-modal', false)
    },
    previewImage(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.articleData.image_data = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      const formData = new FormData()
      formData.append('file', file)

      store.dispatch('store-media/addImageMedia', { data: formData })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })

          const { url } = response.data
          Editor.insertEmbed(cursorLocation, 'image', url)
          resetUploader()
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
    handleImageRemoved(image) {
      store.dispatch('store-media/removeImageMedia', { image })
        .then(() => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.busy = false
          this.processing = false
        })
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      typeToText,
    } = tableArticle()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      isStatusToText,
      typeToText,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
</style>
