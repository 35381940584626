import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_article', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_article', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_article', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_article', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_article', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_article', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_article/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addImageArticle(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_image_article', params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
