import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function tableArticle() {
  // Use toast
  const toast = useToast()

  const refArticleTable = ref(null)

  const msgList = {
    errorfetch: t2('Error fetching {module} list', { module: t('Article') }),
    type: `${t('Type')} » ${t('ID')}`,
    image: `${t('Image')}`,
    subject: `${t('Date/Time')} » ${t('Subject')}, ${t('Title')}, ${t('Content')}  » ${t('Author')}`,
    status: `${t('Status')} » ${t('Suggest')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'type', sortable: true, label: msgList.type },
    { key: 'image', sortable: false, label: msgList.image },
    { key: 'subject', sortable: false, label: msgList.subject },
    { key: 'status', sortable: false, label: msgList.status },
    { key: 'actions', label: msgList.actions },
  ]

  const perPage = ref(10)
  const totalArticles = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('date_time')
  const isSortDirDesc = ref(true)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refArticleTable.value ? refArticleTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalArticles.value,
    }
  })

  const refetchData = () => {
    refArticleTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getListArticle = (ctx, callback) => {
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
    }
    store
      .dispatch('store-article/getListArticle', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalArticles.value = total
        localStorage.setItem('filterArticle', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgList.errorfetch,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const isStatusVariant = isstatus => {
    if (isstatus === 'active') return 'success'
    if (isstatus === 'inactive') return 'danger'
    return 'danger'
  }

  const isStatusToText = isstatus => {
    if (isstatus === 'active') return 'Active'
    if (isstatus === 'inactive') return 'Inactive'
    return ''
  }
  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  const typeToText = type => {
    if (type === 'news') return 'News & PR'
    if (type === 'about') return 'About Us'
    if (type === 'knowledge') return 'Knowledge'
    return ''
  }

  const suggestToText = suggest => {
    if (suggest === false) return 'Normal'
    if (suggest === true) return 'Suggest'
    return ''
  }
  const suggestVariant = suggest => {
    if (suggest === false) return 'secondary'
    if (suggest === true) return 'info'
    return 'danger'
  }

  return {
    getListArticle,
    tableColumns,
    perPage,
    currentPage,
    totalArticles,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refArticleTable,

    refetchData,
    isStatusVariant,
    isStatusToText,
    textFirstUpper,
    typeToText,
    suggestToText,
    suggestVariant,
  }
}
